<template>
  <div class="page bg-white">
    <div v-if="!state.userCanLogin" class="page d-flex justify-content-center align-content-center">
      <van-loading v-if="!state.wechatAuthError" class="justify-content-center align-content-center" color="#7232dd"
        size="36" vertical>
        加载中
      </van-loading>
      <van-empty v-else image="error" :description="state.wechatAuthError">
        <van-button type="primary" plain @click="redoWechatAuth">重新授权</van-button>
      </van-empty>
    </div>
    <div v-else>
      <van-cell-group>
        <template #title>
          <img v-if="state.userinfo.avatar" :src="state.userinfo.avatar" alt="头像" width="20" height="20"
            class="rounded-circle" />
          {{ state.userinfo.nickname }}
        </template>

        <van-config-provider :theme-vars="themeVars">
          <van-tabs v-model:active="activeTab" class="pt-2">
            <van-tab title="密码登陆" class="pt-2">
              <van-field v-model.trim="state.phone" type="tel" maxlength="11" label-width="4em" label="手机号"
                placeholder="请输入手机号码" :disabled="state.realPhone ? true : false" />
              <van-field v-model.trim="state.password" type="password" maxlength="32" label-width="4em" label="密码"
                placeholder="请输入登陆密码" />
            </van-tab>
            <van-tab title="短信登陆" class="pt-2">
              <van-field v-model.trim="state.phone" type="tel" maxlength="11" label-width="4em" label="手机号"
                placeholder="请输入手机号码" :disabled="state.realPhone ? true : false" />
              <van-field v-model.number="state.smscode" type="number" maxlength="4" label-width="4em" label="验证码"
                placeholder="请输入4位验证码">
                <template #button>
                  <van-button v-if="state.remainingTime > 0" size="small" disabled plain type="primary">{{
                    state.smsButtonText }}</van-button>
                  <van-button v-else size="small" plain type="primary" @click="sendCode">{{
                    state.smsButtonText }}</van-button>
                </template>
              </van-field>
            </van-tab>
          </van-tabs>
        </van-config-provider>
        <van-field v-if="state.has_invitation_code && !state.realPhone" v-model.trim="state.invitation_code" type="text"
          maxlength="16" label-width="4em" label="邀请码" :disabled="true" />
        <van-cell>
          <template #value>
            <van-checkbox v-model="state.argee">同意本平台用户协议和隐私政策</van-checkbox>
          </template>
        </van-cell>
      </van-cell-group>

      <van-row class="py-4">
        <van-col span="6"></van-col>
        <van-col span="12" class="text-center">
          <van-button type="primary" class="mb-2" block @click="login">{{
            submitButtonText
          }}</van-button>
          <a href="javascript:void(0);" @click="$router.push({ name: 'Agreement' })">用户协议</a>
          &amp;
          <a href="javascript:void(0);" @click="$router.push({ name: 'Privacy' })">隐私政策</a>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>
    </div>
    <!-- end.else -->
  </div>
</template>

<script>
import {
  Row,
  Col,
  Field,
  Checkbox,
  Toast,
  Tab,
  Tabs,
  Notify,
  Image as VanImage,
} from "vant";
import { useStore } from "vuex";
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sendSmsCode } from "@/api/sms.service";
import { getAuthUrl, wechatAuth } from "@/api/wechat.service";
import { LOGIN, SET_AUTH } from "@/store/modules/auth";
import {
  hideWechatOptionMenu,
  reportClientBug,
} from "@/utils/util";
import { createDeviceDetector } from "next-vue-device-detector";

export default {
  name: "Login",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [VanImage.name]: VanImage,
  },

  setup() {
    const device = createDeviceDetector();
    const { mobile, wechat } = device;

    const activeTab = ref(0);
    const submitButtonText = ref("登录");
    const themeVars = {
      tabsBottomBarColor: "#1989fa",
    };
    watch(
      () => activeTab.value,
      (newTabId) => {
        if (newTabId == 0) {
          submitButtonText.value = "登录";
          state.smscode = "";
        } else {
          submitButtonText.value = "注册/登录";
          state.password = "";
        }
      }
    );

    let timer = null;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userCanLogin: false,
      wechatAuthError: null,
      userinfo: { phone: "" },
      realPhone: "", // 微信用户已经绑定过的手机, 此时的 phone 是脱敏显示的
      phone: "",
      password: "",
      smscode: "",
      invitation_code: "",
      has_invitation_code: false,
      argee: false,
      smsButtonText: "发送验证码",
      remainingTime: 0,
    });

    const redoWechatAuth = () => {
      const url = location.href.split("/login")[0];
      window.location.href = `${url}/login?reauth`;
    };

    const checkPhone = () => {
      if (!state.phone) {
        Toast({ message: "请输入手机号码", position: "bottom" });
        return false;
      }
      if (state.phone.length != 11) {
        Toast({ message: "手机号码不正确", position: "bottom" });
        return false;
      }
      const reg = /^1(\d){10}$/;
      if (state.realPhone) {
        if (!reg.test(state.realPhone)) {
          Toast({ message: "手机号码不正确(1)", position: "bottom" });
          return false;
        }
      } else if (!reg.test(state.phone)) {
        Toast({ message: "手机号码不正确(2)", position: "bottom" });
        return false;
      }
      return true;
    };

    const checkCode = () => {
      if (!state.smscode) {
        Toast({ message: "请输入验证码", position: "bottom" });
        return false;
      }
      if (state.smscode.toString().length != 4) {
        Toast({ message: "验证码不正确", position: "bottom" });
        return false;
      }
      return true;
    };

    const tickTick = () => {
      // 定时器 90 秒后可重发短信验证码
      state.remainingTime = 90;
      timer = setInterval(() => {
        if (state.remainingTime == 0) {
          state.smsButtonText = "发送验证码";
          clearInterval(timer);
        } else {
          state.remainingTime--;
          state.smsButtonText = `${state.remainingTime}秒后可重发`;
        }
      }, 1000);
    };

    const sendCode = async () => {
      if (!checkPhone()) {
        return;
      }
      try {
        const resp = await sendSmsCode(
          state.realPhone ? state.realPhone : state.phone
        );
        if (resp.result.BizId) {
          tickTick();
          Toast({ message: "已发送", position: "bottom" });
          state.smscode = "";
        } else {
          Notify({ type: "warning", message: resp.msg });
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Notify({ type: "warning", message: msg ? msg : "发送验证码失败" });
        reportClientBug(error, "Login.sendCode");
      }
    };

    const login = async () => {
      if (!state.argee) {
        return Toast({ message: "请先同意用户协议", position: "bottom" });
      }
      if (!checkPhone()) {
        return;
      }
      if (activeTab.value == 0) {
        if (!state.password || state.password.length < 8) {
          return Toast({
            message: "请输入不低于8位的密码",
            position: "bottom",
          });
        }
      } else if (activeTab.value == 0) {
        if (!checkCode()) {
          return;
        }
      }
      store
        .dispatch(LOGIN, {
          phone: state.realPhone ? state.realPhone : state.phone,
          password: state.password,
          code: state.smscode,
          invitation_code: state.invitation_code,
          is_mobile: mobile ? 1 : 0,
          is_wechat: wechat ? 1 : 0,
        })
        .then(() => {
          Toast({ message: "欢迎登录", position: "bottom", duration: 3500 });
          window.sessionStorage.removeItem("preauth");
          const redirect = route.query.redirect || "/";
          router.push(decodeURIComponent(redirect));
        })
        .catch((error) => {
          Notify({ type: "danger", message: error });
          reportClientBug(error, "Login.login");
        });
    };

    // 直接使用微信授权登录, 无需再次输入账号密码登录
    const preauth = async (queryCode, queryState) => {
      try {
        const { result } = await wechatAuth(
          queryCode,
          queryState,
          "",
          state.invitation_code
        );

        store.commit(SET_AUTH, result);
        Toast({
          message: "欢迎登录",
          position: "bottom",
          duration: 3500,
        });

        const redirect = window.sessionStorage.getItem("redirect") || '/';
        window.sessionStorage.removeItem("redirect");
        window.location.href = decodeURIComponent(redirect);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        state.wechatAuthError = msg ? msg : "获取微信授权失败";
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
        reportClientBug(error, "Login.preauth");
      }
    };

    const jumptoWechat = () => {
      const callback_url = location.href.split("#")[0];
      const redirect_url = getAuthUrl(callback_url);
      window.location.href = redirect_url;
    };

    onMounted(() => {
      const inviteData = JSON.parse(window.localStorage.getItem("invite"));
      if (inviteData && inviteData.code) {
        state.invitation_code = inviteData.code;
        state.has_invitation_code = true;
      }
      const { code, state } = route.query;
      if (code && state) {
        preauth(code, state);
        hideWechatOptionMenu();
      } else {
        setTimeout(() => {
          jumptoWechat();
        }, 1200);
      }
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return {
      activeTab,
      submitButtonText,
      themeVars,
      state,
      redoWechatAuth,
      sendCode,
      login,
    };
  },
};
</script>
